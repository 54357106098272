import { ApolloClient, InMemoryCache } from 'apollo-boost'
import { HttpLink } from 'apollo-link-http'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'
import { split } from 'apollo-link'
import config from '~/config'

interface Definition {
  kind: string
  operation?: string
}

// Create an http link:
const httpLink = new HttpLink({
  uri: `${location.protocol}//${location.host}${config.dashboardRootUrl}/graphql`,
})

// Create a WebSocket link:
const wsLink = new WebSocketLink({
  uri: `${location.protocol.indexOf('https') >= 0 ? 'wss' : 'ws'}://${location.host}${
    config.dashboardRootUrl
  }/graphql`,
  options: {
    reconnect: true,
  },
})

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation }: Definition = getMainDefinition(query)
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  wsLink,
  httpLink,
)

export default new ApolloClient({
  link,
  cache: new InMemoryCache(),
})
